import React from "react";
import logo from "../../Assets/logo.png";
import "./Footer.scss";
// import discord from "../../Assets/discordLogo.svg";
// import twitter from "../../Assets/twitterLogo.svg";
// import instagram from "../../Assets/instagramLogo.svg";

function Footer() {
  return (
    <div id="footer">
      <div className="footerContainer">
        <div className="footerTop">
          <div className="footerLeft">
            <div className="alignLogo">
              <img className="navlogo" src={logo} alt="logo" />
            </div>
            <div className="footerLeftText">
              <p className="company">© Resellers Basement 2023</p>
              <p>email@resellersbasement.com</p>
            </div>
          </div>
          <div className="footerRedirects">
            <ul>
              <p className="listHeader">General</p>
              <li>
                <a href="/#landing" className="listText">
                  Home
                </a>
              </li>
              <li>
                <a href="/#about" className="listText">
                  About
                </a>
              </li>
            </ul>
            <ul>
              <p className="listHeader">Services</p>
              <li>
                <a href="https://www.frugalseason.com/" className="listText">
                  Frugal
                </a>
              </li>
              {/* <li><a href='/#success' className='listText'>Success</a></li> */}
              <li>
                <a href="https://www.freebiefrenzy.io/" className="listText">
                  Freebie Frenzy
                </a>
              </li>
              <li>
                <a href="https://www.travelhackings.com/" className="listText">
                  Travel Hacked
                </a>
              </li>
            </ul>
            <ul>
              <p className="listHeader">Socials</p>
              <li>
                <a href="/terms" className="listText">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="/privacy" className="listText">
                  Privacy & Policy
                </a>
              </li>
            </ul>
            <ul>
              <p className="listHeader">Contact</p>
              <li>
                <a href="mailto:support@resellology.io" className="listText">
                  Discord
                </a>
                {/* <img className="socialLogo" src={discord} alt="logo" /> */}
              </li>
              <li>
                <a
                  href="https://mobile.twitter.com/resellbasement"
                  target="_blank"
                  rel="noreferrer"
                  className="listText"
                >
                  Twitter
                </a>
                {/* <img className="socialLogo" src={twitter} alt="logo" /> */}
              </li>
              <li>
                <a href="mailto:support@resellology.io" className="listText">
                  Instagram
                </a>
                {/* <img className="socialLogo" src={instagram} alt="logo" /> */}
              </li>
            </ul>
          </div>
          <div className="startResellingBtn">
            <form className="d-flex">
              <a href="https://whop.com/resellers-basement?a=resellersbasement" target="_blank">
                <div className="btn dashboardButton text-nowrap">
                  Start Reselling
                </div>
              </a>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
