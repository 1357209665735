import Home from './Pages/Home/Home'
import Terms from './Pages/Legal/Terms'
import Privacy from './Pages/Legal/Privacy'
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route name="forms" path="/:slug" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
