import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Home.scss";
// Import Components
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import Stats from "../../Components/Stats/Stats";
// Images
import frenzyLogo from "../../Assets/freebiefrenzy.png";
import frugal from "../../Assets/frugal.png";
import gift from "../../Assets/gift.svg";
import plane from "../../Assets/plane.png";
import resellingGroup from "../../Assets/resellgroup.png";
import resellUniv from "../../Assets/reselluniv.png";
import success from "../../Assets/successimage.png";
import travelLogo from "../../Assets/travelhackings.png";
// import alerts from "../../Assets/alertsImage.png";
import bell from "../../Assets/bell.svg";
import coin from "../../Assets/coin.svg";
import console from "../../Assets/console.svg";
import credit from "../../Assets/credit.svg";
import graph from "../../Assets/graph.svg";
import moneySign from "../../Assets/moneySign.svg";
import logo from "../../Assets/navlogo.png";
import present from "../../Assets/present.svg";
import priceTag from "../../Assets/priceTag.svg";
import standings from "../../Assets/standings.svg";
import videoImg1 from "../../Assets/videoBackImg1.png";
import videoImg2 from "../../Assets/videoBackImg2.png";
import { SubmitForm } from "../../Actions/forms";
//Videos
import {
  Field, Form, Formik
} from 'formik';
import { useParams } from "react-router-dom";
import freebieVideo from "../../Assets/ff_promo.mp4";
import frugalVideo from "../../Assets/frugal_promo.mov";
import travelVideo from "../../Assets/travelVideo.mov";
import {
  OptinCommunity,
  OptinCommunityDiscord,
  PriceErrorSubscription,
  ResellersBasementDiscord
} from "../../Components/forms/Forms";



const forms = {
  'discord':{
    element:<OptinCommunity/>,
    source:"OPTIN_COURSE",
    listName:"RB Optin Discord"
  },
  'course':{
    element:<OptinCommunityDiscord/>,
    source:"COURSE",
    listName:"RB Optin Course"
  },
  'course-and-community': {
    element:<ResellersBasementDiscord />,
    source:"OPTIN_COURSE_AND_COMMUNITY",
    listName:"RB Optin Course and Community"
  },
  'pricerrors': {
    element:<PriceErrorSubscription />,
    source:"OPTIN_NORMAL",
    listName:"RB Optin Price Errors"
  }
}


function SubForm({slug}) {
  return (
    <Formik initialValues={
      {
        firstName: "",
        lastName: "",
        email: "",
      }
    }
    onSubmit={
      (e) => {
        SubmitForm(
          e.firstName,
          e.email,
          forms[slug].source,
          forms[slug].listName,
        ).then((e)=>{
          if (e){
            return toast('✅ Email submitted, check your Inbox!', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            })
          }else{
            return toast('❌ Failed to submit email', {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            })
          }
        })
      }
    }
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        if (!values.firstName) {
          errors.firstName = "Required";
        } else if (values.firstName.split(" ").length > 1) {
          errors.firstName = "Please enter only your first name";
        }
        return errors;
      }}>
      <Form className="emailForm">
        <div className="emailInputOuter">
          <div className="emailInput">
            <Field className="emailFormInput" type="email" name="email" placeholder="johnoe@gmail.com" />
            <Field className="emailFormInput" type="" name="firstName" placeholder="John" />
          </div>
          <div className="submit-button">
            <button className="continueButton" type="submit" precv >Submit</button>
            <p>By clicking the button you consent to receive emails from us.</p>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

function EmailOptIn({ slug }) {
  if (!forms[slug]) {
    return <div>404</div>
  }
  return (
    <div id="emailoptin">
      <ToastContainer
        position="top-right"
        modetheme="dark"
        autoClose={3000}
      />
      <div className="content">
        <div className="nav">
          <img className="navlogo" src={logo} alt="logo" />
        </div>
        <div className="emailOptInForm" >
          {slug == "course-and-community"  && <h1>Join Our Email List</h1>}
          {
          slug == "course-and-community" 
          &&<SubForm slug = {slug}/>}
          {
            forms[slug].element
          }
          <SubForm slug = {slug}/>
        </div>
      </div>
      <div className="footer">
        <p>©2023 Frugal SZN</p>
      </div>
    </div>
  );
}

function Landing() {
  return (
    <div id="landing">
      <div className="landingContent">
        <div className="landingText">
          <h1><b>LEARN TO START A SIDE HUSTLE</b></h1>
          <p>
            The most lucrative online community to date. A large network of
            ambitious individuals aspiring to be successful entrepreneurs. Gain
            <span className="textGradient">free</span> access to resources to
            monetize on the modern world of business.
          </p>
        </div>
        <div className="blueBlob" />
        <div className="purpBlob" />
        <div className="landingTools">
          <h2>
            Free Tools <img src={gift} alt="gift" className="giftIcon" />
          </h2>
          <LandCards />
        </div>
        <Stats />
      </div>
    </div>
  );
}

function About() {
  return (
    <div className="about" id="about">
      <div className="twoColNew">
        <div className="aboutText">
          <h2 className="mb30" style={{ textAlign: 'left' }}>Diversify your income streams.</h2>
          <p>
            The world’s best <span className="textGradient">free</span>{" "}
            reselling community. Start getting insight on popular products with{" "}
            <span className="textGradient">international</span> demand. The
            perfect venture for new and experienced entrepreneurs.
          </p>
          <p>A community created by resellers, for resellers.</p>
          <div className="featuresSectionNew featuresBoxes">
            <div className="featuresNew">
              {/* <div className="featureIconBox"></div> */}
              <h3 className="featuresText">Quality Leads</h3>
              <p>Daily pings for high-profit buys, with guaranteed sales.</p>
            </div>
            <div className="featuresNew">
              {/* <div className="featureIconBox"></div> */}
              <h3 className="featuresText">24/7 Monitors</h3>
              <p>The fastest monitors, covering the highest demand items.</p>
            </div>
            <div className="featuresNew">
              {/* <div className="featureIconBox"></div> */}
              <h3 className="featuresText">Quick Cashout</h3>
              <p>Cashout your items with us to increase your capital.</p>
            </div>
          </div>
        </div>
        <div>
          <img src={success} alt="success" className="aboutImage" />
        </div>
      </div>
    </div>
  );
}

function Frugal() {
  return (
    <div className="about" id="frugal">
      <div className="frugalHeader">
        <img src={frugal} alt="frugal" className="frugalLogo" />
        <h2>
          A cook group built by hustlers, <b>for hustlers.</b>
        </h2>
      </div>
      <div className="twoCol">
        <div>
          <div className="videoContainer">
            <div className="blueBlob" />
            <div className="purpBlob" />
            <img src={videoImg1} alt="circle" className="backImg1" />
            <img src={videoImg2} alt="circle" className="backImg2" />
            <video
              autoPlay
              muted
              loop
              controls
              webkit-playsinline
              playsinline
              src={frugalVideo}
              type="video/mp4"
            />
          </div>
          <div className="textMobile">
            <p>
              A <span className="textGradient">community</span> with like minded individuals with sights aimed to
              bring you a different e-commerce experience.
            </p>
            <p>
              Capitalize on the latest <span className="textGradient">trends</span> not known by the public. We make
              this easy.
            </p>
          </div>
          <div className="featuresSection">
            <div className="features">
              <div className="featureIconBox">
                {" "}
                <img src={priceTag} alt="priceTag" className="featureIcons" />
              </div>
              <h3 className="featuresText">Price Errors / Deals </h3>
            </div>
            <div className="features">
              <div className="featureIconBox">
                {" "}
                <img src={console} alt="console" id="consoleIcon" />
              </div>
              <h3 className="featuresText">Online / Retail Arbitrage</h3>
            </div>
            <div className="features">
              <div className="featureIconBox">
                {" "}
                <img src={graph} alt="graph" className="featureIcons" />
              </div>
              <h3 className="featuresText">Bug Bounties</h3>
            </div>
          </div>
        </div>
        <div className="textDesktop">
          <p>
            A <span className="textGradient">community </span> with like minded individuals with sights aimed to bring
            you a different e-commerce experience.
          </p>
          <p>
            Capitalize on the latest <span className="textGradient">trends</span> not known by the public. We make
            this easy.
          </p>
          <a href="https://www.frugalseason.com/" className="btn joinButton">
            Learn More
          </a>
        </div>
        <a href="https://www.frugalseason.com/" className="btn joinButton hideDesktop">
          Learn More
        </a>
      </div>
    </div>
  );
}

function Frenzy() {
  return (
    <div className="about" id="freebie">
      <div className="twoCol">
        <div>
          <div className="frugalCenter">
            <img src={frenzyLogo} alt="frugal" className="frugalLogo" />
          </div>
          <h2 className="freebieHeader">
            <b>Revolutionizing</b> the price error industry
          </h2>
          <div className="videoContainer">
            {/* <img src={alerts} alt="alerts" className="alertsImg" /> */}
            <video
              autoPlay
              webkit-playsinline
              muted
              loop
              controls
              playsinline
              width="100%"
              height="auto"
              src={freebieVideo}
              type="video/mp4"
            />
          </div>
        </div>
        <div className="freebieText">
          <p>
            The <span className="textGradient"> first </span> ever bot created
            to source and checkout all price errors and deals on the web. Every
            resellers tool needed to fight inflation. Never pay full price
            again.
          </p>
          <div className="featuresSection mb20">
            <div className="features">
              <div className="featureIconBox">
                <img src={present} alt="present" className="featureIcons" />
              </div>
              <h3 className="featuresText">Amazon Freebies (100% off)</h3>
            </div>
            <div className="features">
              <div className="featureIconBox">
                <img
                  src={moneySign}
                  alt="dollar sign"
                  className="featureIcons"
                />
              </div>
              <h3 className="featuresText">Deal Detection</h3>
            </div>
            <div className="features">
              <div className="featureIconBox">
                <img src={bell} alt="bell" className="featureIcons" />
              </div>
              <h3 className="featuresText">Personal Pingers</h3>
            </div>
          </div>
          <a href="https://www.freebiefrenzy.io/" className="btn frenzyLearnBtn joinButton">
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}

function Travel() {
  return (
    <div id="travel">
      <div className="about" id="frugal">
        <img src={plane} className="planeImg" alt="plane" />
        <div className="frugalHeader">
          <img src={travelLogo} alt="travel" className="frugalLogo" />
          <h2>
            Educating the world
            <br />
            <b>about travel.</b>
          </h2>
        </div>
        <div className="twoCol">
          <div className="videoSection">
            <span className="blueCircle"></span>
            <span className="purpleCircle"></span>
            <div className="videoContainer">
              <video
                autoPlay
                webkit-playsinline
                muted
                loop
                controls
                playsinline
                width="100%"
                height="auto"
                src={travelVideo}
                type="video/mp4"
              />
            </div>
            <div className="textMobile">
              <p>
                Discover a better way to{" "}
                <span className="textGradient"> outsmart travel</span>. Learn to
                travel consistently at a fraction of the price 100% of the time with
                secrets this industry will never make public.
              </p>
            </div>
            <div className="featuresSection withGap">
              <div className="features">
                <div className="featureIconBox">
                  <img src={coin} alt="coin" className="featureIcons" />
                </div>
                <h3 className="featuresText">Unlimited free items</h3>
              </div>
              <div className="features">
                <div className="featureIconBox">
                  <img src={credit} alt="credit" className="featureIcons" />
                </div>
                <h3 className="featuresText">Improve your credit</h3>
              </div>
              <div className="features">
                <div className="featureIconBox">
                  <img src={standings} alt="standings" className="featureIcons" />
                </div>
                <h3 className="featuresText">Gain top statuses</h3>
              </div>
            </div>
          </div>
          <div className="textDesktop">
            <p>
              Discover a better way to{" "}
              <span className="textGradient"> outsmart travel</span>. Learn to
              travel consistently at a fraction of the price 100% of the time with
              secrets this industry will never make public.
            </p>
            <a href="https://www.travelhackings.com/" className="btn joinButton travelbtn ">
              Learn More
            </a>
          </div>
          <a href="https://www.travelhackings.com/" className="btn joinButton travelbtn hideDesktop">
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}

function LandCards() {
  return (
    <div className="landCards">
      <div className="cardContainer">
        <div className="cardHeader">
          <h2 className="headerText">
            Best <b>FREE</b> Reselling Group
          </h2>
        </div>
        <div className="cardInner">
          <img src={resellingGroup} alt="shoes" className="cardImage" />
          <a href="https://discord.com/invite/reseller" target="_blank" rel="noopener noreferrer" className="btn joinButton">
            Join Now
          </a>
        </div>
      </div>
      <div className="cardContainer">
        <div className="cardHeader">
          <h2 className="headerText">Reseller’s University</h2>
        </div>
        <div className="cardInner">
          <img src={resellUniv} alt="shoes" className="cardImage" />
          <a href="https://festive-soup-e07.notion.site/Resellers-Basement-University-08f60ade1e264c29968d00f37ae4479c" target="_target" className="btn learnButton">
            Start Learning
          </a>
        </div>
      </div>
    </div>
  )
}

function Elevate() {
  return (
    <div id="elevate">
      <div className="frugalHeader">
        <h2 className="elevateText">
          Free tools to <b>elevate</b> your reselling game.
        </h2>
      </div>
      <LandCards />
    </div>
  );
}

function Home(
) {
  const { slug } = useParams();
  return (
    slug ? <EmailOptIn slug={slug} /> : <div>
      <Navbar homeNav="navbar navbar-expand-lg  bg-custom" />
      <Landing />
      <About />
      <Elevate />
      <Footer />
    </div>
  );
}

export default Home;
