import React from "react";
import "./Stats.scss";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

function Analytics() {
  return (
    <div id="stats">
      <div className="statGrid">
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <div>
              <h2 className="statAnimate">

                {isVisible ? (
                  <CountUp
                    separator=","
                    start={0}
                    end={10000}
                    duration="1"
                    suffix="+"
                  />
                ) : null}
              </h2>
              <p>New resellers created</p>
            </div>
          )}
        </VisibilitySensor>
        <div>
          <h2 className="statAnimate">$0</h2>
          <p>Capital required to start</p>
        </div>
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <div>
              <h2 className="statAnimate">
                {isVisible ? (
                  <CountUp
                    prefix="$"
                    start={0}
                    end={1000}
                    duration="1"
                    separator=","
                  />
                ) : null}
              </h2>
              <p>Average monthly profit</p>
            </div>
          )}
        </VisibilitySensor>
      </div>
    </div>
  );
}

export default Analytics;
