import React from 'react'
import Navbar from "../../Components/Navbar/Navbar";
import './Legal.scss'

function Terms() {
    return (
        <div id='legal'>
            <Navbar homeNav="navbar navbar-expand-md navbar-light bg-custom" />
            <div className='legalSpace'>
                <h1 className='legalHeader'>Terms of Service</h1>
                <p>Rios LLC does not offer refunds to any user under any circumstances outside our refund policy and by purchasing a membership to our service, you agree that all payments collected from you are irreversible and you will not attempt to seek a refund via chargebacks. By buying or redeeming a subscription, you agree to be charged on a monthly renewal basis. Rios LLC reserves the right to revoke your access to your membership for any reason at any given time, and by purchasing a membership you acknowledge that your membership can be revoked whether or not your membership purchase falls in the exclusions stated in these terms.</p>
                <h3>License</h3>
                <p>Rios LLC grants you a revocable, non-exclusive, non-transferable, limited license to download, install and use our service strictly in accordance with the terms of this Agreement. These Terms &amp; Conditions are a contract between you and Rios LLC (referred to in these Terms &amp; Conditions as "Rios LLC", "us", "we" , &ldquo;Rios LLC&rdquo; , or "our"), the provider of the Rios LLC website and the services accessible from the Rios LLC websites (which are collectively referred to in these Terms &amp; Conditions as the "Rios LLC"). You are agreeing to be bound by these Terms &amp; Conditions. If you do not agree to these Terms &amp; Conditions, please do not use the Service. In these Terms &amp; Conditions, "you" refers both to you as an individual and to the entity you represent. If you violate any of these Terms &amp; Conditions, we reserve the right to cancel your account or block access to your account without notice.</p>
                <h3>Restrictions</h3>
                <p>You agree not to, and you will not permit others to: &bull; License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose or otherwise commercially exploit the service or make the platform available to any third party. &bull; Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the service. &bull; Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of or its affiliates, partners, suppliers or the licensors of the service.</p>
                <h3>Refund Policy</h3>
                <p>All subscription related charges (Includes all Rios LLC subscriptions) are final and non-refundable, unless you have received a duplicate subscription charge.</p>
                <h3>Cookies</h3>
                <p>We use "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our service. If you disable Cookies, you may not be able to access certain functionalities on our website.</p>
                <h3>Changes to Our Terms &amp; Services</h3>
                <p>You acknowledge and agree that we may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at our sole discretion, without prior notice to you. You may stop using the Service at any time. You acknowledge and agree that if we disable access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which is contained in your account. If we decide to change our Terms &amp; Conditions, we will post those changes on this page.</p>
            </div>
        </div>
    )
}

export default Terms