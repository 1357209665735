import check from "../../Assets/green-checkmark-icon.svg";
import Forms from "./Forms.scss"

export function OptinCommunity() {
    return (
        <div className="form-center">

            <div className="top-header">
                <h1><b>LEARN TO START A SIDE HUSTLE</b></h1>
            </div>

            <div className="list-item-container">
                <p className="sub-text">
                    We find the best side hustles to make money. 🔥 <br /> If you join our private discord server (Reseller's Basement), you get:
                </p>
                
                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Access to our fully unique method to get free food from outlets like Chipotle, Dunkin' Donuts, and <span className="textGradient">more.</span></p>
                </div>

                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Daily deals on select items on Amazon selling for up to 89% off.</p>
                </div>

                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Daily notifications about sellers messing up the pricing on listings - so you can get items like PS5s or TVs for a <span className="textGradient">HUGE discount</span> </p>
                </div>

                <div className="list-item">
                    <p><b>All you have to do is fill the form below and we'll send you the invite link via email.👇</b></p>
                </div>
            </div>
        </div>
    );
}

export function OptinCommunityDiscord(){
    return (
        <div className="form-center">
        <div className = "top-header">
            <h1><b>LEARN HOW TO MAKE MONEY FROM $0, BECOME A SHARP MINDED ENTREPRENEUR, AND ESCAPE THE RAT RACE IN OUR FREE COURSE</b></h1>
        </div>
            <div className="list-item-container">
                <p className="sub-text">
                    In this newsletter, you will find the following:
                </p>
                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Tips and examples on starting new side hustles with or without requiring any money</p>
                </div>
            
                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Step-by-step guidance on how to start selling on Amazon</p>
                </div>
            
                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Discover popular side hustles that everyone is currently doing</p>
                </div>
            
                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Free guides to help you start your next side hustle, such as selling on platforms like Facebook, Mercari, eBay, and more</p>
                </div>

                            
                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Tips and tricks to help you avoid common problems</p>
                </div>
            
                                         
                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Inspiring success stories of individuals who have found success with their side hustles.</p>
                </div>

                <div className="list-item">
                    <p><b>All for free, just fill out this form and we'll send you the course via email 👇</b></p>
                </div>
            </div>
        </div>
      );
}

export function ResellersBasementDiscord() {
    return (
        <div className="form-center">
            <div className = "top-header">
                <h1 className = "top-header"><b>Learn how to make money via reselling (even if you have $0), Make friends with like minded hustlers, get INSANE deals from Amazon, and much more</b></h1>
            </div>
            <div className="list-item-container">
                <div>
                    <p>If you join our private discord server (<b>Reseller's Basement</b>), you get:</p>
                    <div className="list-item">
                        <img src={check} alt="check" className="checkicon" />
                        <p>Access to our fully unique method to get free food from outlets like Chipotle, Dunkin' Donuts, and more.</p>
                    </div>

                    <div className="list-item">
                        <img src={check} alt="check" className="checkicon" />
                        <p>Daily deals on select items on Amazon selling for up to 89% off.</p>
                    </div>

                    <div className="list-item">
                        <img src={check} alt="check" className="checkicon" />
                        <p>Daily notifications about sellers messing up the pricing on listings - so you can get items like PS5s or TVs for a HUGE discount (and then profit 💰)</p>
                    </div>

                </div>
                <div className="custom-plus">
                    <p><b>PLUS</b></p>
                </div>

                <div>
                    <p>we're giving away our free course which will teach you exactly how to:</p>

                    <div className="list-item">
                        <img src={check} alt="check" className="checkicon" />
                        <p>Use Facebook Marketplace to make your first $50-{">"}$100-{">"}$300 and even $1000 per month.</p>
                    </div>

                    <div className="list-item">
                        <img src={check} alt="check" className="checkicon" />
                        <p>Resell Profitably (With scenarios and examples for proper training)</p>
                    </div>

                    <div className="list-item">
                        <img src={check} alt="check" className="checkicon" />
                        <p>Start making money with reselling even if you have $0</p>
                    </div>

                    <div className="list-item">
                        <img src={check} alt="check" className="checkicon" />
                        <p>Have a list of principles, videos, books, and podcasts you need to become a killer shark in the online money world in no time.</p>
                    </div>

                    <div className="list-item">
                        <img src={check} alt="check" className="checkicon" />
                        <p>Use Loops to your advantage (Real World infinite money glitch)</p>
                    </div>

                    <div className="list-item">
                        <p><b>All for free, just fill out this form and we'll send you the course and the membership link via email:</b></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function PriceErrorSubscription() {
    return (
        <div className="form-center">
            <div className = "top-header">
                <h1><b>Get the Most Lucrative Price Errors Sent to Your Inbox</b></h1>
            </div>
            <div className="list-item-container">
                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>A price error happens when an online seller messes up the price on Amazon or BestBuy.</p>
                </div>

                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Sometimes $299 TV sets are available for $2.99 because of these mistakes.</p>
                </div>

                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>We track these price errors and bring you the latest deals - as fast as possible. So you can cop these items early and profit off of them.</p>
                </div>

                <div className="list-item">
                    <img src={check} alt="check" className="checkicon" />
                    <p>Keep it or sell it for a higher profit margin. Your call. Our goal is to keep bringing you these insane deals. For free. Straight to your Inbox.</p>
                </div>

                <div className="list-item">
                    <p><b>To get subscribed, simply fill this form:</b></p>
                </div>
            </div>
        </div>
    );
}
