import axios from 'axios'

var apiUrl = process.env.REACT_APP_PRODUCTION_API

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    apiUrl = process.env.REACT_APP_DEVELOPMENT_API
    console.log('Development API URL: ' + apiUrl)
}

const session = axios.create({
    baseURL: apiUrl,
});


async function SubmitForm(name, email, source, list) {
    const response = await session.post('/email-list', {
        email: email,
        firstName: name,
        source: source,
        listName: list
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
    return response.status === 201
}

export {
    SubmitForm
}