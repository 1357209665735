import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import logo from "../../Assets/navlogo.png";
import './Navbar.scss';
// import discord from '../../Assets/Icons/discordLogo.svg'
// import twitter from '../../Assets/Icons/twitterLogo.svg'
// import instagram from '../../Assets/Icons/instagramLogo.svg'


const Navbar = ({ homeNav }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <div id="navbar">
            <nav className={homeNav}>
                <div className="container-fluid navbarWidth ">
                    <a href="/#landing">
                        <img className="navlogo" src={logo} alt="logo" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={handleShow}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        activeClassName={"current"}
                                        href="/#landing"
                                        onClick={handleClose}
                                    >
                                        Home
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        activeClassName={"current"}
                                        href="/#about"
                                        onClick={handleClose}
                                    >
                                        About
                                    </a>
                                </li>
                            </ul>
                        </Modal.Body>
                    </Modal>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav mt-1 mb-md-0 scrollspy ms-auto me-auto">
                            <li className="nav-item ms-md-3">
                                <a
                                    className="nav-link"
                                    activeClassName={"current"}
                                    href="/#landing"
                                >
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    activeClassName={"current"}
                                    href="/#about"
                                >
                                    About
                                </a>
                            </li>
                        </ul>
                        <form className="d-flex">
                            <a href="https://whop.com/resellers-basement?a=resellersbasement" target="_blank" rel="noopener noreferrer">
                                <div className="btn purchaseButton text-nowrap">Join Now</div>
                            </a>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Navbar;
